<template>
  <b-container fluid>
    <b-card>
      <b-table
        class="text-center"
        :fields="tableFields"
        :items="items"
        responsive
      >
        <template #cell(action_type)="data">
          {{ actionIdToActionType[data.item.action_type_id] }}
        </template>

        <template #cell(rate)="data">
          <span v-if="data.item.charge_type === 'fixed'">
            {{ data.item.rate | value }}
          </span>

          <span v-else>
            {{ data.item.rate | percentage }}
          </span>
        </template>

        <template #cell(charge_type)="data">
          {{ chargeTypeInPortuguese[data.item.charge_type] }}
        </template>
      </b-table>
    </b-card>
  </b-container>
</template>

<script>
import { BContainer, BTable, BCard } from 'bootstrap-vue';
import RatesService from '@/services/osiris/RatesService';

import { actionIdToActionType } from '@/enums/actionIdToActionType';

export default {
    components: {
        BContainer, BTable, BCard,
    },

    data() {
        return {
            items: [],
        };
    },

    computed: {
        tableFields() {
            return [
                { key: 'action_type', label: 'Tipo' },
                { key: 'charge_type', label: 'Tipo da Cobrança' },
                { key: 'rate', label: 'Taxa' },
            ];
        },

        actionIdToActionType() {
            return actionIdToActionType;
        },

        chargeTypeInPortuguese() {
            return {
                fixed: 'Fixo',
                percentage: 'Porcentagem',
            };
        },
    },

    async mounted() {
        await this.getRates();
    },

    methods: {
        async getRates() {
            this.isLoading(true);

            const { groupId } = this.$store.getters['user/user'];
            const { data } = await RatesService.ratesByGroupId(groupId);

            this.items = data.data;
            this.isLoading(false);
        },
    },
};
</script>
